import endpoints from 'helper/endpoints'
import {
  fireSuccessToast,
  globalApiErrorHandler,
  globalLoginErrorHandler,
  globalLoginRefreshErrorHandler,
  rethrow,
} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_USER_PROFILE_MANAGER_FAIL,
  GET_USER_PROFILE_MANAGER_REQUEST,
  GET_USER_PROFILE_MANAGER_SUCCESS,
  USER_CREATE_IMPERSONATION_FAIL,
  USER_CREATE_IMPERSONATION_REQUEST,
  USER_CREATE_IMPERSONATION_SUCCESS,
  USER_DESTROY_IMPERSONATION_FAIL,
  USER_DESTROY_IMPERSONATION_REQUEST,
  USER_DESTROY_IMPERSONATION_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  EDIT_PASSWORD_FAIL,
  EDIT_PASSWORD_REQUEST,
  EDIT_PASSWORD_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  USER_LOGIN_REFRESH_REQUEST,
  USER_LOGIN_REFRESH_SUCCESS,
  USER_LOGIN_REFRESH_FAIL,
  GET_PROFILE_LEVEL_REQUEST,
  GET_PROFILE_LEVEL_SUCCESS,
  GET_PROFILE_LEVEL_FAIL,
  GET_PROFILE_BROKER_REQUEST,
  GET_PROFILE_BROKER_SUCCESS,
  GET_PROFILE_BROKER_FAIL,
} from 'redux/actionTypes'

export const userLoginRefresh = () =>
  function (dispatch) {
    const logged = localStorage.getItem('logged')
    if (!logged) {
      dispatch({type: USER_LOGIN_REFRESH_FAIL})
      return
    }
    dispatch({type: USER_LOGIN_REFRESH_REQUEST})
    return httpClient
      .get(endpoints.authProfile)
      .then((response) => {
        localStorage.setItem('logged', 'true')
        dispatch({
          type: USER_LOGIN_REFRESH_SUCCESS,
          data: response.data,
        })
      })
      .catch(
        rethrow(() => {
          localStorage.removeItem('logged')
          dispatch({type: USER_LOGIN_REFRESH_FAIL})
        })
      )
      .catch(globalLoginRefreshErrorHandler)
      .catch(globalApiErrorHandler)
  }

export const userLogin = (loginData) =>
  function (dispatch) {
    dispatch({type: USER_LOGIN_REQUEST})
    return httpClient
      .post(endpoints.login, {
        email: loginData.email,
        password: loginData.password,
      })
      .then((response) => {
        localStorage.setItem('logged', 'true')
        dispatch({
          type: USER_LOGIN_SUCCESS,
          data: {...response.data},
        })
      })
      .catch(
        rethrow(() => {
          localStorage.removeItem('logged')
          dispatch({type: USER_LOGIN_FAIL})
        })
      )
      .catch(globalLoginErrorHandler)
      .catch(globalApiErrorHandler)
  }

export const userLogout = () =>
  function (dispatch) {
    return dispatch(destroyUserImpersonation())
      .then(() => {
        dispatch({type: USER_LOGOUT_REQUEST})
        return httpClient
          .del(endpoints.logout)
          .then((response) => {
            localStorage.removeItem('logged')
            localStorage.removeItem('impersonation')
            dispatch({
              type: USER_LOGOUT_SUCCESS,
              data: response.data,
            })
            fireSuccessToast('Odhlášení proběhlo úspěšně')
            return Promise.resolve()
          })
          .catch(rethrow(() => dispatch({type: USER_LOGOUT_FAIL})))
          .catch(globalApiErrorHandler)
      })
      .catch(rethrow(() => dispatch({type: USER_LOGOUT_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const getProfile = () =>
  function (dispatch) {
    dispatch({type: GET_PROFILE_REQUEST})
    return httpClient
      .get(endpoints.profile)
      .then((response) => {
        dispatch({
          type: GET_PROFILE_SUCCESS,
          data: response.data,
        })
        return response.data
      })
      .catch(rethrow(() => dispatch({type: GET_PROFILE_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const getProfileLevel = () =>
  function (dispatch) {
    dispatch({type: GET_PROFILE_LEVEL_REQUEST})
    return httpClient
      .get(endpoints.profile + '/level')
      .then((response) => {
        dispatch({
          type: GET_PROFILE_LEVEL_SUCCESS,
          data: response.data,
        })
        return response.data
      })
      .catch(rethrow(() => dispatch({type: GET_PROFILE_LEVEL_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const rescueProfileLevel = () => {
  return (dispatch) => {
    return httpClient
      .put(endpoints.profile + '/level/rescue')
      .then((response) => {
        return response.data
      })
      .catch(globalApiErrorHandler)
  }
}

export const getProfileBroker = () =>
  function (dispatch) {
    dispatch({type: GET_PROFILE_BROKER_REQUEST})
    return httpClient
      .get(endpoints.profile + '/broker')
      .then((response) => {
        dispatch({
          type: GET_PROFILE_BROKER_SUCCESS,
          data: response.data,
        })
        return response.data
      })
      .catch(rethrow(() => dispatch({type: GET_PROFILE_BROKER_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const editUserPassword = (data) =>
  function (dispatch) {
    dispatch({type: EDIT_PASSWORD_REQUEST})
    return httpClient
      .patch(endpoints.password, data)
      .then((response) => {
        dispatch({
          type: EDIT_PASSWORD_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: EDIT_PASSWORD_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const getUserProfileManager = () =>
  function (dispatch) {
    dispatch({type: GET_USER_PROFILE_MANAGER_REQUEST})
    return httpClient
      .get(endpoints.manager)
      .then((response) => {
        dispatch({
          type: GET_USER_PROFILE_MANAGER_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_USER_PROFILE_MANAGER_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const createUserImpersonation = (recommenderId) =>
  function (dispatch) {
    dispatch({type: USER_CREATE_IMPERSONATION_REQUEST})
    return httpClient
      .post(endpoints.impersonation, {
        recommender_id: recommenderId,
      })
      .then((response) => {
        localStorage.setItem('impersonation', JSON.stringify(response.data))
        dispatch({
          type: USER_CREATE_IMPERSONATION_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: USER_CREATE_IMPERSONATION_FAIL})))
      .catch(globalLoginErrorHandler)
      .catch(globalApiErrorHandler)
  }

export const destroyUserImpersonation = () =>
  function (dispatch) {
    dispatch({type: USER_DESTROY_IMPERSONATION_REQUEST})
    return httpClient
      .del(endpoints.impersonation)
      .then((response) => {
        localStorage.removeItem('impersonation')
        dispatch(userLoginRefresh())
        dispatch({
          type: USER_DESTROY_IMPERSONATION_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: USER_DESTROY_IMPERSONATION_FAIL})))
      .catch(globalLoginErrorHandler)
      .catch(globalApiErrorHandler)
  }
