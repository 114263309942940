/** undefined == no error for the react-final-form **/

// connect multiple validations together,
// otherwise you can omit this function and use single validation on Field

export const composeValidators = (...validators) => (value) => {
  return validators.reduce((error, validator) => error || validator(value), undefined)
}

// required validation for input field
export const required = (value) => {
  return value ? undefined : 'Povinné pole'
}

// form level validation of identity of passwords
export const samePasswordValidation = (values) => {
  if (values.password !== values.password_confirmation) {
    return {password_confirmation: 'Hesla se musejí shodovat'}
  }
  return undefined
}

export const validateEmail = (value) => {
  if (!value?.includes('@')) {
    return 'Špatná emailové adresa'
  }
  return undefined
}

export const validateNumbers = (value) => {
  if (!value?.match(/^\d+$/)) {
    return 'Pole přijímá pouze číslice'
  }
}

export const validatePostalCode = (value) => {
  if (!value?.match(/^[\d ]+$/)) {
    return 'Pole musí obsahovat pouze číslice a mezeru'
  }
}

export const validatePhoneNumber = (value) => {
  if (!value?.match(/^[\d +]+$/)) {
    return 'Není platné telefonní číslo'
  }
}
