import DetailPageMenu from 'component/DetailPageMenu'
import {removeTrailingSlash} from 'helper/functions'
import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Switch, Route, useRouteMatch, Redirect} from 'react-router-dom'
import styles from './detailPage.module.scss'
import YourContact from 'component/yourContact/YourContact'
import {getProfile, getProfileBroker, getProfileLevel} from 'redux/actions/user'
import RecommenderDetail from 'component/RecommenderDetail'
import PropTypes from 'prop-types'
import Overview from 'component/overview/Overview'
import {getClients} from 'redux/actions/clientAction'
import Contacts from 'component/contacts/Contacts'
import Invoice from 'component/invoice/Invoice'

const DetailPage = (props) => {
  const {
    getProfileLevel,
    getProfileBroker,
    getProfile,
    getClients,
    profile,
    profileBroker,
    profileLevel,
    profileLevelLoading,
    clients,
    clientsLoading,
  } = props

  let routeMatch = useRouteMatch()

  useEffect(() => {
    getProfile()
    getProfileLevel()
    getProfileBroker()
    getClients()
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [getProfile, getProfileLevel, getProfileBroker, getClients])

  return (
    <div className={styles.DetailPage}>
      <div className={styles.topPartBackground}>
        <div className={styles.topPart}>
          <RecommenderDetail profile={profile} />
          <YourContact broker={profileBroker} />
        </div>
      </div>
      <div>
        <Switch>
          {/* přehled */}
          <Route path={`${routeMatch.path}/prehled`}>
            <DetailPageMenu activeLink="prehled" />
            <div className={styles.scrollTable}>
              <Overview
                profileLevel={profileLevel}
                clients={clients}
                clientsLoading={clientsLoading}
                profileLevelLoading={profileLevelLoading}
              />
            </div>
          </Route>
          {/* kontakty */}
          <Route path={`${routeMatch.path}/kontakty`}>
            <DetailPageMenu activeLink="kontakty" />
            <div className={styles.scrollTable}>
              <Contacts />
            </div>
          </Route>
          {/* fakturace */}
          <Route path={`${routeMatch.path}/fakturace`}>
            <DetailPageMenu activeLink="fakturace" />
            <div className={styles.scrollTable}>
              <Invoice />
            </div>
          </Route>

          <Route exact path={routeMatch.path}>
            <Redirect to={`${removeTrailingSlash(routeMatch.url)}/prehled`} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

DetailPage.propTypes = {
  getProfile: PropTypes.func,
  getProfileLevel: PropTypes.func,
  getProfileBroker: PropTypes.func,
  profile: PropTypes.object,
  profileBroker: PropTypes.object,
  profileBrokerLoading: PropTypes.bool,
  profileLevel: PropTypes.object,
  profileLevelLoading: PropTypes.bool,
  getClients: PropTypes.func,
  clients: PropTypes.array,
  clientsLoading: PropTypes.bool,
  clientsMeta: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    manager: state.user.manager,
    profile: state.user.profile,
    profileBroker: state.user.profileBroker,
    profileBrokerLoading: state.user.profileBrokerLoading,
    profileLevel: state.user.profileLevel,
    profileLevelLoading: state.user.profileLevelLoading,
    clients: state.clients.clients,
    clientsLoading: state.clients.clientsLoading,
    clientsMeta: state.clients.clientsMeta,
  }
}

const mapDispatchToProps = {
  getProfile,
  getProfileLevel,
  getProfileBroker,
  getClients,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPage)
