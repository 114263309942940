import {
  GET_IMPERSONATION_REQUEST,
  GET_IMPERSONATION_SUCCESS,
  GET_IMPERSONATION_FAIL,
} from 'redux/actionTypes'

const initialState = {
  recommendersLoading: false,
  recommenders: [],
  recommendersMeta: {},
}

const impersonationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IMPERSONATION_REQUEST: {
      return {
        ...state,
        recommendersLoading: true,
      }
    }
    case GET_IMPERSONATION_SUCCESS: {
      return {
        ...state,
        recommendersLoading: true,
        recommenders: action.data.objects,
        recommendersMeta: action.data.meta,
      }
    }
    case GET_IMPERSONATION_FAIL: {
      return {
        ...state,
        recommendersLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default impersonationReducer
