import React, {useEffect, useState} from 'react'
import Loader from 'component/Loader'
import cx from 'classnames'
import styles from 'component/table.module.scss'
import invoiceStyle from 'component/invoice/invoice.module.scss'
import classNames from 'classnames/bind'
import {ReactComponent as Dots} from 'asset/img/three_dots.svg'
import {connect} from 'react-redux'
import {getInvoices} from 'redux/actions/invoiceActions'
import PropTypes from 'prop-types'
import DownloadPdf from 'component/invoice/DownloadPdf'
import endpoints from 'helper/endpoints'
import moment from 'moment'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import NumberFormat from 'react-number-format'

const fi = classNames.bind(flaticon)

const Invoice = (props) => {
  const {getInvoices, invoices, invoicesLoading, invoicesMeta} = props

  const [page, setPage] = useState(0)

  const maxPage = Math.ceil(invoicesMeta.total_count / invoicesMeta.limit)

  const renderPageNumbers = () => {
    const pages = []

    const finalPages = []

    // add one to solve index issue (index starts 0, page 1)
    // subtract two for edge page number
    const leftEdgePageNumber = page + 1 - 2
    const rightEdgePageNumber = maxPage - page - 2

    // returns 5 pages, 2 from left, current and 2 from right
    for (let i = leftEdgePageNumber; i <= page + 1 + 2; i++) {
      if (i > 0 && i <= maxPage) {
        if (!finalPages.includes(i)) {
          finalPages.push(i)
        }
      }
    }

    // left side of pagination (pages and dots)
    if (leftEdgePageNumber === 2) {
      finalPages.unshift(1)
    } else if (leftEdgePageNumber === 3) {
      finalPages.unshift(...[1, 2])
    } else if (leftEdgePageNumber > 3) {
      finalPages.unshift(...[1, 'threeDots'])
    }

    // right side of pagination (pages and dots)
    if (rightEdgePageNumber === 2) {
      finalPages.push(maxPage)
    } else if (rightEdgePageNumber === 3) {
      finalPages.push(...[maxPage - 1, maxPage])
    } else if (rightEdgePageNumber > 3) {
      finalPages.push(...['threeDots', maxPage])
    }

    finalPages.forEach((pageNumber, index) => {
      if (pageNumber === 'threeDots') {
        pages.push(
          <div className={styles.dots} key={`threeDots${index}`}>
            <Dots />
          </div>
        )
      } else {
        pages.push(
          <div
            className={classNames(styles.page, page === pageNumber - 1 ? styles.active : '')}
            key={pageNumber}
            onClick={() => goToPage(pageNumber - 1)}
          >
            {pageNumber}
          </div>
        )
      }
    })

    return pages
  }

  const handleNextPage = () => {
    if (invoicesMeta.limit * (page + 1) < invoicesMeta.total_count) {
      setPage((prevState) => prevState + 1)
    }
  }

  const goToPage = (page) => {
    if (page > 0 || invoicesMeta.limit * (page + 1) < invoicesMeta.total_count) {
      setPage(page)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevState) => prevState - 1)
    }
  }

  useEffect(() => {
    getInvoices(page)
  }, [])

  return (
    <div className={invoiceStyle.invoice}>
      <div className={invoiceStyle.tableHeaderRow}>
        <div>Měsíc</div>
        <div>Částka</div>
        <div>PDF</div>
        <div className={invoiceStyle.isPaidCell}>Stav faktury</div>
      </div>
      {invoicesLoading ? (
        <Loader />
      ) : (
        <div className={invoiceStyle.table}>
          {invoices.map((invoice, index) => (
            <div key={index} className={invoiceStyle.tableRow}>
              <div className={invoiceStyle.monthCell}>
                {invoice?.month ? moment(invoice.month).format('MMMM YY') : ''}
              </div>
              <div>
                <NumberFormat
                  thousandSeparator={' '}
                  displayType="text"
                  suffix={' Kč'}
                  value={invoice?.amount}
                />
              </div>
              <div>
                {invoice.is_downloadable ? (
                  <DownloadPdf downloadUrl={`${endpoints.invoices}/${invoice.id}/download`} />
                ) : (
                  ''
                )}
              </div>
              <div className={invoiceStyle.isPaidCell}>
                {invoice.is_paid ? (
                  <span>
                    <span className={invoiceStyle.greenCheck}>●</span> Vyfakturováno
                  </span>
                ) : (
                  <span>
                    <span className={invoiceStyle.redCross}>●</span> Nevyfakturováno
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={styles.pagination}>
        {maxPage > 1 && (
          <>
            <span
              onClick={handlePreviousPage}
              className={classNames(fi('flaticon-left-arrow'), styles.previousPagination)}
            />
            <span
              onClick={handleNextPage}
              className={classNames(fi('flaticon-right-arrow'), styles.nextPagination)}
            />
          </>
        )}
        {renderPageNumbers()}
      </div>
    </div>
  )
}

Invoice.propTypes = {
  getInvoices: PropTypes.func,
  invoices: PropTypes.array,
  invoicesLoading: PropTypes.bool,
  invoicesMeta: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices.invoices,
    invoicesLoading: state.invoices.invoicesLoading,
    invoicesMeta: state.invoices.invoicesMeta,
  }
}

const mapDispatchToProps = {
  getInvoices,
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
