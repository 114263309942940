const resolveEnv = (envName) => {
  if (ENVIRONMENT === 'production') {
    return window._env_[envName]
  }
  return process.env[envName]
}

export const ENVIRONMENT = process.env.NODE_ENV
export const REACT_APP_BACKEND_URL = resolveEnv('REACT_APP_BACKEND_URL')
export const REACT_PUBLIC_API_KEY = resolveEnv('REACT_PUBLIC_API_KEY')

export const MOCK_API = false

export const MOCK_PORT_MAPPING = {
  auth: 8000,
  client: 8001,
  common: 8002,
  recommender: 8003,
}

export const TABLET_SCREEN_WIDTH = 1024
export const PHONE_SCREEN_WIDTH = 800
export const DATE_FORMAT = 'DD. MM. YYYY'
export const DATABASE_DATE_FORMAT = 'YYYY-MM-DD'

export const CONTRACT_KIND = [
  {
    value: 'sale',
    label: 'Prodej nemovitosti',
  },
  {
    value: 'lease',
    label: 'Jednorázový pronájem',
  },
  {
    value: 'management',
    label: 'Správa pronájmu nemovitosti',
  },
]

export const ESTATE_KIND = [
  {value: 'flat', label: 'Byt'},
  {value: 'house', label: 'Dům/vila'},
  {value: 'cottage', label: 'Chata/rekreační object'},
  {value: 'commercial', label: 'Nebytový prostor'},
  {value: 'garage', label: 'Garáž'},
  {value: 'land', label: 'Pozemek'},
  {value: 'complex', label: 'Bytový dům'},
  {value: 'other', label: 'Jiné'},
]

export const ESTATE_KIND_MANAGEMENT = [
  {value: 'flat', label: 'Byt'},
  {value: 'house', label: 'Dům/vila'},
  {value: 'cottage', label: 'Chata/rekreační object'},
  {value: 'garage', label: 'Garáž'},
  {value: 'complex', label: 'Bytový dům'},
]

export const CONTRACT_STATUS = [
  {
    value: '',
    label: '(Stav obchodu)',
  },
  {
    value: 'signed',
    label: 'Zobchodováno',
  },
  {
    value: 'active',
    label: 'Aktivní',
  },
  {
    value: 'closed',
    label: 'Ukončeno',
  },
]

export const PHONE_EXTENSION = [
  {
    value: '+420',
    label: '(+420) Česká republika'
  },
  {
    value: '+421',
    label: '(+421) Slovensko'
  },
  {
    value: '+491',
    label: '(+491) Německo'
  },
  {
    value: '+492',
    label: '(+492) Německo'
  },
  {
    value: '+493',
    label: '(+493) Německo'
  },
  {
    value: '+494',
    label: '(+494) Německo'
  },
  {
    value: '+495',
    label: '(+495) Německo'
  },
  {
    value: '+496',
    label: '(+496) Německo'
  },
  {
    value: '+497',
    label: '(+497) Německo'
  },
  {
    value: '+498',
    label: '(+498) Německo'
  },
  {
    value: '+499',
    label: '(+499) Německo'
  },
]
