import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames/bind'
import {useHistory} from 'react-router-dom'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import styles from './topBar.module.scss'
import logoWhite from 'asset/img/logoWhite.png'
import logoIcon from 'asset/img/logoIcon.png'
import {userLogout} from 'redux/actions/user'
import {PHONE_SCREEN_WIDTH} from 'helper/constants'
import Dialog from 'component/Dialog/Dialog'
import PredatKontakt from 'component/predatKontakt/PredatKontakt'
import {createRecommendations, getRecommendations} from 'redux/actions/recommendationActions'
import {fireErrorToast, fireSuccessToast, mapErrorResponseToForm} from 'helper/functions'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const TopBar = (props) => {
  const {profile, userLogout, loginLayout, createRecommendations, getRecommendations} = props

  const mobileView = window.innerWidth < PHONE_SCREEN_WIDTH

  const [mobileViewLogo, setMobileViewLogo] = useState(mobileView)
  const [recommendDialogVisible, setRecommendDialogVisible] = useState(false)
  const [messageSendVisible, setMessageSendVisible] = useState(false)

  let history = useHistory()

  const [path, setPath] = useState('')

  const redirectHome = () => {
    history.push('/')
  }

  const handleResizePhone = (e) => {
    if (e.target.innerWidth >= PHONE_SCREEN_WIDTH) {
      if (mobileViewLogo === true) {
        setMobileViewLogo(false)
      }
    } else {
      if (mobileViewLogo === false) {
        setMobileViewLogo(true)
      }
    }
  }

  const handleOpenDialog = () => {
    setRecommendDialogVisible(true)
  }
  const handleCloseDialog = () => {
    setRecommendDialogVisible(false)
  }

  const handleSubmit = (values) => {
    const preparedValues = {
      ...values,
      phone: values?.phone && values?.phone_extension?.[0]?.value && `${values?.phone_extension?.[0]?.value}${values?.phone}`,
      estate_kind: values.estate_kind?.[0]?.value,
      interested_in: values.interested_in?.[0]?.value,
    }

    delete preparedValues.phone_extension

    return createRecommendations(preparedValues)
      .then((res) => {
        fireSuccessToast('Kontakt odeslán')
        handleCloseDialog()
        getRecommendations()
      })
      .catch((err) => {
        fireErrorToast('Odeslání kontaktu selhalo')
        return mapErrorResponseToForm(err)
      })
  }

  const handleLogout = () => {
    userLogout().then((res) => history.push('/login'))
  }

  useEffect(() => {
    window.addEventListener('resize', (e) => {
      handleResizePhone(e)
    })
    return () => {
      window.removeEventListener('resize', this)
    }
  }, [mobileViewLogo])

  history.listen((location) => {
    setPath(location.pathname)
  })

  useEffect(() => {
    if (history?.location?.pathname?.includes('/spolupracovnici/')) {
      setPath(history.location.pathname)
    }
    history.listen((location) => {
      setPath(location.pathname)
    })
  }, [])

  return (
    <div className={styles.TopBar}>
      <div className={styles.mainTopBar}>
        <img
          onClick={redirectHome}
          className={styles.logo}
          src={mobileViewLogo ? logoIcon : logoWhite}
          alt="Váš Domovník"
        />
        {!loginLayout && (
          <>
            {path?.includes('/spolupracovnici/') && !mobileView && (
              <div className={styles.conductTitle}>{profile}</div>
            )}

            {recommendDialogVisible && (
              <Dialog
                title={!messageSendVisible ? 'Informace o kontaktu' : ''}
                handleClose={handleCloseDialog}
                sendVisible={messageSendVisible}
              >
                <PredatKontakt handleSubmit={handleSubmit} handleCloseDialog={handleCloseDialog} />
              </Dialog>
            )}

            <div className={classNames(styles.marginLeftAuto)}>
              {path?.includes('/spolupracovnici/') && (
                <div onClick={handleOpenDialog} className={classNames(styles.contactButton)}>
                  PŘEDAT KONTAKT
                </div>
              )}
              <div className={classNames(styles.logoutButton)} onClick={handleLogout}>
                <span className={classNames(fi('flaticon-logout'), cx('iconLogout'))} />
                {!mobileView && <>&nbsp;&nbsp;Odhlásit se</>}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  impersonatedName: state.user.impersonatedData?.name,
  profile: state.user.profile?.name,
})

const mapDispatchToProps = {
  userLogout,
  createRecommendations,
  getRecommendations,
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)
