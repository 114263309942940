import React from 'react'
import PropTypes from 'prop-types'
import styles from './layout.module.scss'
import TopBar from 'component/TopBar'
import Footer from 'component/Footer'
import cx from 'classnames'

const Layout = (props) => {
  const {children, loginLayout} = props
  return (
    <div className={styles.Layout}>
      <TopBar loginLayout={loginLayout} />
      <div className={cx(styles.content, loginLayout ? styles.loginContent : '')}>{children}</div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
