import {GET_INVOICES_FAIL, GET_INVOICES_REQUEST, GET_INVOICES_SUCCESS} from 'redux/actionTypes'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {globalApiErrorHandler, rethrow} from 'helper/functions'

export const getInvoices = (page) => {
  const params = {
    limit: 20,
    offset: page ? page * 20 : 0,
  }

  return (dispatch) => {
    dispatch({type: GET_INVOICES_REQUEST})
    return httpClient
      .get(endpoints.invoices, params)
      .then((res) => {
        dispatch({type: GET_INVOICES_SUCCESS, data: res.data})
      })
      .catch(rethrow(() => dispatch({type: GET_INVOICES_FAIL})))
      .catch(globalApiErrorHandler)
  }
}
