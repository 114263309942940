import endpoints from 'helper/endpoints'
import {globalApiErrorHandler, rethrow} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_IMPERSONATION_REQUEST,
  GET_IMPERSONATION_SUCCESS,
  GET_IMPERSONATION_FAIL,
} from 'redux/actionTypes'

export const getRecommenders = (limit = 1000, offset = 0) => {
  return (dispatch) => {
    dispatch({type: GET_IMPERSONATION_REQUEST})
    return httpClient
      .get(`${endpoints.impersonation}/recommenders`, {
        limit: limit,
        offset: offset,
      })
      .then((res) => {
        dispatch({
          type: GET_IMPERSONATION_SUCCESS,
          data: res.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_IMPERSONATION_FAIL})))
      .catch(globalApiErrorHandler)
  }
}
