import formStyles from 'component/form/formFields.module.scss'
import React from 'react'
import PropTypes from 'prop-types'

const FormRow = (props) => {
  return <div className={formStyles.row}>{props.children}</div>
}

FormRow.propTypes = {
  children: PropTypes.node,
}

export default FormRow
