import React from 'react'
import PropTypes from 'prop-types'
import styles from 'component/recommenderDetail.module.scss'

const RecommenderDetail = (props) => {
  const {profile} = props
  const {tax_id, org_id, address, phone, email} = profile

  return (
    <div className={styles.detail}>
      <table>
        <tbody>
          <tr>
            <td className={styles.title}>IČ:</td>
            <td>{org_id && org_id}</td>
          </tr>
          {tax_id &&
          <tr>
            <td className={styles.title}>DIČ:</td>
            <td>{tax_id && tax_id}</td>
          </tr>
          }
          <tr>
            <td className={styles.title}>Sídlo:</td>
            <td>{address && address}</td>
          </tr>
          <tr>
            <td className={styles.title}>Telefon:</td>
            <td>{phone && phone}</td>
          </tr>
          <tr>
            <td className={styles.title}>Email:</td>
            <td>{email && email}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

RecommenderDetail.propTypes = {
  profile: PropTypes.object,
}

export default RecommenderDetail
