import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  GET_PROFILE_SUCCESS,
  USER_DESTROY_IMPERSONATION_SUCCESS,
  USER_CREATE_IMPERSONATION_SUCCESS,
  USER_CREATE_IMPERSONATION_FAIL,
  GET_USER_PROFILE_MANAGER_SUCCESS,
  USER_LOGIN_REFRESH_SUCCESS,
  USER_LOGIN_REFRESH_FAIL,
  GET_PROFILE_FAIL,
  GET_PROFILE_BROKER_REQUEST,
  GET_PROFILE_BROKER_SUCCESS,
  GET_PROFILE_BROKER_FAIL,
  GET_PROFILE_LEVEL_REQUEST,
  GET_PROFILE_LEVEL_SUCCESS,
  GET_PROFILE_LEVEL_FAIL,
} from 'redux/actionTypes'

const logged = localStorage.getItem('logged')

const initialState = {
  logged: !!logged,
  loginProgress: false,
  logoutProgress: false,
  data: {},
  impersonated: false,
  impersonatedData: {},
  manager: {},
  profile: {},
  profileKind: {},

  profileBroker: {},
  profileBrokerLoading: false,

  profileLevel: {},
  profileLevelLoading: false,
}

const userLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST: {
      return {
        ...state,
        loginProgress: true,
      }
    }
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
        logged: true,
        loginProgress: false,
        data: action.data,
      }
    }
    case USER_LOGIN_FAIL: {
      return {
        ...state,
        loginProgress: false,
      }
    }
    case USER_LOGOUT_REQUEST: {
      return {
        ...state,
        logoutProgress: true,
      }
    }
    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        logged: false,
        logoutProgress: false,
        data: {},
        impersonated: false,
        impersonatedData: {},
      }
    }
    case USER_LOGOUT_FAIL: {
      return {
        ...state,
        logoutProgress: false,
      }
    }
    case USER_LOGIN_REFRESH_SUCCESS: {
      return {
        ...state,
        data: action.data,
        logged: true,
      }
    }
    case USER_LOGIN_REFRESH_FAIL: {
      return {
        ...state,
        logged: false,
      }
    }
    case GET_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.data,
      }
    }
    case GET_PROFILE_FAIL: {
      return {
        ...state,
        profile: {},
      }
    }
    case GET_PROFILE_BROKER_REQUEST: {
      return {
        ...state,
        profileBrokerLoading: true,
      }
    }
    case GET_PROFILE_BROKER_SUCCESS: {
      return {
        ...state,
        profileBroker: action.data,
        profileBrokerLoading: false,
      }
    }
    case GET_PROFILE_BROKER_FAIL: {
      return {
        ...state,
        profileBrokerLoading: false,
      }
    }
    case GET_PROFILE_LEVEL_REQUEST: {
      return {
        ...state,
        profileLevelLoading: true,
      }
    }
    case GET_PROFILE_LEVEL_SUCCESS: {
      return {
        ...state,
        profileLevel: action.data,
        profileLevelLoading: false,
      }
    }
    case GET_PROFILE_LEVEL_FAIL: {
      return {
        ...state,
        profileLevelLoading: false,
      }
    }

    case USER_CREATE_IMPERSONATION_SUCCESS: {
      return {
        ...state,
        impersonated: true,
        impersonatedData: action.data,
      }
    }
    case USER_CREATE_IMPERSONATION_FAIL: {
      return {
        ...state,
        impersonated: false,
        impersonatedData: {},
      }
    }
    case USER_DESTROY_IMPERSONATION_SUCCESS: {
      return {
        ...state,
        impersonated: false,
        impersonatedData: {},
      }
    }
    case GET_USER_PROFILE_MANAGER_SUCCESS: {
      return {
        ...state,
        manager: action.data,
      }
    }
    default: {
      return state
    }
  }
}

export default userLoginReducer
