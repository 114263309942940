import React from 'react'
import {Form, Field} from 'react-final-form'
import formStyles from 'component/form/formFields.module.scss'
import recommendStyle from 'page/recommendPage.module.scss'
import TextInput from 'component/form/TextInput'
import FormRow from 'component/form/FormRow'
import {connect} from 'react-redux'
import {sendDoporucitZnamemu} from 'redux/actions/doporucitZnamemu'
import {required, validateEmail, validatePhoneNumber} from 'helper/validations'
import SelectInput from 'component/form/SelectInput'
import {CONTRACT_KIND, ESTATE_KIND, ESTATE_KIND_MANAGEMENT, PHONE_EXTENSION} from 'helper/constants'
import {ReactComponent as Check} from 'asset/img/check_circle.svg'
import TelNumberInput from 'component/form/TelNumberInput'

const PredatKontakt = (props) => {
  const {handleCloseDialog, handleSubmit, sendVisible} = props

  return (
    <>
      {!sendVisible ? (
        <div className={recommendStyle.predatKontakt}>
          <Form
            onSubmit={handleSubmit}
            render={(formProps) => {
              const interestedValue = formProps.values?.interested_in?.[0]?.value
              const stateKind = formProps.values?.estate_kind?.[0]
              if (interestedValue === 'management') {
                if (stateKind && !ESTATE_KIND_MANAGEMENT.includes(stateKind)) {
                  formProps.errors['estate_kind'] = 'Vyberte jinou možnost'
                }
              }

              return (
                <form onSubmit={formProps.handleSubmit} className={formStyles.form}>
                  <FormRow>
                    <Field
                      name="name_firstname"
                      label="Jméno"
                      component={TextInput}
                      validate={required}
                    />
                    <Field
                      name="name_lastname"
                      label="Příjmení"
                      component={TextInput}
                      validate={required}
                    />
                  </FormRow>

                  <FormRow>
                    <div className={formStyles.fieldSection}>
                    <Field
                      name="phone_extension"
                      label="Předvolba"
                      component={SelectInput}
                      labelField={'value'}
                      options={PHONE_EXTENSION}
                      className={formStyles.phoneExtension}
                      validate={required}
                      optionLabel={'label'}
                    />
                    <Field
                      label="Telefon"
                      name="phone"
                      component={TelNumberInput}
                      styles={formStyles.phoneField}
                      validate={validatePhoneNumber}
                    />
                    </div>
                    <Field
                      name="email"
                      label="E-mail"
                      component={TextInput}
                      validate={validateEmail}
                    />
                  </FormRow>

                  <FormRow>
                    <Field
                      name="interested_in"
                      label="Zájem o"
                      component={SelectInput}
                      className={formStyles.fieldRow}
                      options={CONTRACT_KIND}
                      styles={formStyles.fieldRow}
                      validate={required}
                    />
                  </FormRow>

                  <div className={recommendStyle.dialogTitle}>Nemovitost</div>

                  <FormRow>
                    {formProps.values?.interested_in?.[0]?.value === 'management' ? (
                      <Field
                        name="estate_kind"
                        label="Typ"
                        component={SelectInput}
                        className={formStyles.fieldRow}
                        options={ESTATE_KIND_MANAGEMENT}
                        styles={formStyles.fieldRow}
                        validate={required}
                      />
                    ) : (
                      <Field
                        name="estate_kind"
                        label="Typ"
                        component={SelectInput}
                        className={formStyles.fieldRow}
                        options={ESTATE_KIND}
                        styles={formStyles.fieldRow}
                        validate={required}
                      />
                    )}
                  </FormRow>
                  <FormRow>
                    <Field
                      name="estate_city"
                      label="Obec"
                      component={TextInput}
                      styles={formStyles.fieldRow}
                      className={formStyles.fieldRow}
                      validate={required}
                    />
                  </FormRow>
                  <p className={recommendStyle.biggerText}>
                    Spol. VÁŠ DOMOVNÍK působí aktuálně v lokalitách Plzeň a okolí (okruh cca 70 km
                    kolem Plzně) + Praha. Ostatní lokality na zvážení vedení společnosti.
                  </p>
                  <p className={recommendStyle.smallerText}>
                    VÁŠ DOMOVNÍK si vyhrazuje právo odmítnout případný kontakt z důvodu nepůsobení v
                    dané lokalitě.
                  </p>

                  <div className={formStyles.buttons}>
                    <button
                      className={formStyles.button}
                      type="submit"
                      disabled={formProps.submitting}
                    >
                      ODESLAT
                    </button>
                  </div>
                </form>
              )
            }}
          />
        </div>
      ) : (
        <div className={recommendStyle.messageSent}>
          <Check />
          <div className={recommendStyle.title}>Zpráva odeslána</div>
          <p>
            Děkujeme, že myslíte na ostatní a jako malou pozornost za Vaše úsilí, od nás, prosíme,
            přijiměte pozvání (poukaz) na skvělou výběrovou kávu do kavárny Snílek v Plzni.
            Vychutnejte si ji s Vaším partnerem/partnerkou nebo třeba s tím, komu chcete v
            pronajímání pomoci.
          </p>
          <button className={recommendStyle.recommendButton} onClick={handleCloseDialog}>
            ZAVŘÍT
          </button>
        </div>
      )}
    </>
  )
}

PredatKontakt.propTypes = {}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {sendDoporucitZnamemu}

export default connect(mapStateToProps, mapDispatchToProps)(PredatKontakt)
