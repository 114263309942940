import {getServiceUrl} from 'helper/functions'

const endpoints = {
  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  password: `${getServiceUrl('auth')}/profile/password`,
  authProfile: `${getServiceUrl('auth')}/profile`,

  impersonation: `${getServiceUrl('recommender')}/impersonation`,
  recommendations: `${getServiceUrl('recommender')}/recommendations`,
  profile: `${getServiceUrl('recommender')}/profile`,
  clients: `${getServiceUrl('recommender')}/clients`,
  invoices: `${getServiceUrl('recommender')}/invoices`,

  statistics: `${getServiceUrl('common')}/statistics`,
  interests: `${getServiceUrl('common')}/interests`,
}

export default endpoints
