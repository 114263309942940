import React, {useState} from 'react'
import formStyles from '../form/formFields.module.scss'
import cx from 'classnames'
import classNames from 'classnames/bind'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import PropTypes from 'prop-types'

const fi = classNames.bind(flaticon)

const PasswordInput = (props) => {
  const {label, meta, input, styles, ...rest} = props

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    setVisible((prevState) => !prevState)
  }

  const classNames = cx(formStyles.field, !!input?.value ? formStyles.notEmpty : '', styles)
  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={classNames}>
      <label className={formStyles.label}>{label && label}</label>
      <input
        {...input}
        {...rest}
        className={cx(formStyles.passwordField, errorMessage ? formStyles.errorField : '')}
        type={visible ? 'text' : 'password'}
      />
      <span
        onClick={toggleVisible}
        className={cx(
          fi('flaticon-visibility'),
          formStyles.passwordIcon,
          visible ? formStyles.visible : ''
        )}
      />
      {errorMessage && <span className={formStyles.errorMessage}>{errorMessage}</span>}
    </div>
  )
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  styles: PropTypes.string,
}

export default PasswordInput
