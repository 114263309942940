import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  CLIENT_FILTER_UPDATE,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
} from 'redux/actionTypes'
import {globalApiErrorHandler, rethrow} from 'helper/functions'

export const getClients = (page, filters) => {
  const params = {
    limit: 20,
    offset: page ? page * 20 : 0,
    interested_in_status: filters?.interested_in_status ? filters?.interested_in_status : null,
    name: filters?.name ? filters?.name : null,
  }

  return (dispatch) => {
    dispatch({type: GET_CLIENTS_REQUEST})
    return httpClient
      .get(endpoints.clients, params)
      .then((res) => {
        dispatch({type: GET_CLIENTS_SUCCESS, data: res.data})
      })
      .catch(rethrow(() => dispatch({type: GET_CLIENTS_FAIL})))
      .catch(globalApiErrorHandler)
  }
}

export const updateFilter = (data) => {
  return (dispatch) => {
    dispatch({type: CLIENT_FILTER_UPDATE, data: data})
  }
}
