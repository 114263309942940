import React from 'react'
import PropTypes from 'prop-types'
import styles from './homePageMenu.module.scss'
import HomePageMenuItem from 'component/HomePageMenuItem'
import najemniPohadka from 'asset/img/ico_najemnipohadka.png'
import zajimavosti from 'asset/img/ico_zajimavosti.png'
import oSpolecnosti from 'asset/img/ico_ospolecnosti.png'
import statistiky from 'asset/img/ico_statistiky.png'

const HomePageMenu = (props) => {
  const {oSpolecnostiOnClick, najemniPohadkaOnClick} = props

  return (
    <div className={styles.HomePageMenu}>
      <HomePageMenuItem
        icon={najemniPohadka}
        onClick={najemniPohadkaOnClick}
        text="Nájemní pohádka"
        color="rgb(241,95,96)"
      />
      <HomePageMenuItem
        path="zajimavosti"
        icon={zajimavosti}
        text="Zajímavosti"
        color="rgb(250,187,39)"
      />
      <HomePageMenuItem
        icon={oSpolecnosti}
        onClick={oSpolecnostiOnClick}
        text="O společnosti"
        color="rgb(37,156,183)"
      />
      <HomePageMenuItem
        path="statistiky"
        icon={statistiky}
        text="Statistiky"
        color="rgb(231,9,27)"
      />
    </div>
  )
}

HomePageMenu.propTypes = {
  oSpolecnostiOnClick: PropTypes.func,
  najemniPohadkaOnClick: PropTypes.func,
}

export default HomePageMenu
