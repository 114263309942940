import {
  GET_STATISTICS_REQUEST,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_FAIL,
} from 'redux/actionTypes'

const initialState = {dataReceived: false, getInProgress: false, data: {}}

const getStatisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATISTICS_REQUEST: {
      return {...state, getInProgress: true}
    }
    case GET_STATISTICS_SUCCESS: {
      return {
        ...state,
        dataReceived: true,
        getInProgress: false,
        data: action.data,
      }
    }
    case GET_STATISTICS_FAIL: {
      return {...state, getInProgress: false}
    }
    default: {
      return state
    }
  }
}

export default getStatisticsReducer
