import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './table.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import {ReactComponent as Dots} from 'asset/img/three_dots.svg'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const Table = (props) => {
  const {fields, data, pagination, page, maxPage, nextPage, prevPage, goToPage} = props

  const renderPageNumbers = () => {
    const pages = []

    const finalPages = []

    // add one to solve index issue (index starts 0, page 1)
    // subtract two for edge page number
    const leftEdgePageNumber = page + 1 - 2
    const rightEdgePageNumber = maxPage - page - 2

    // returns 5 pages, 2 from left, current and 2 from right
    for (let i = leftEdgePageNumber; i <= page + 1 + 2; i++) {
      if (i > 0 && i <= maxPage) {
        if (!finalPages.includes(i)) {
          finalPages.push(i)
        }
      }
    }

    // left side of pagination (pages and dots)
    if (leftEdgePageNumber === 2) {
      finalPages.unshift(1)
    } else if (leftEdgePageNumber === 3) {
      finalPages.unshift(...[1, 2])
    } else if (leftEdgePageNumber > 3) {
      finalPages.unshift(...[1, 'threeDots'])
    }

    // right side of pagination (pages and dots)
    if (rightEdgePageNumber === 2) {
      finalPages.push(maxPage)
    } else if (rightEdgePageNumber === 3) {
      finalPages.push(...[maxPage - 1, maxPage])
    } else if (rightEdgePageNumber > 3) {
      finalPages.push(...['threeDots', maxPage])
    }

    finalPages.forEach((pageNumber, index) => {
      if (pageNumber === 'threeDots') {
        pages.push(
          <div className={styles.dots} key={`threeDots${index}`}>
            <Dots />
          </div>
        )
      } else {
        pages.push(
          <div
            className={classNames(styles.page, page === pageNumber - 1 ? styles.active : '')}
            key={pageNumber}
            onClick={() => goToPage(pageNumber - 1)}
          >
            {pageNumber}
          </div>
        )
      }
    })

    return pages
  }

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            {fields?.map((field) => (
              <th key={field.key} className={cx('th', field.styleTh)}>
                {field.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index} className={styles.row}>
              {fields.map((field) => (
                <td key={field.key} className={cx('td', field.style)}>
                  {field.render ? field.render(item[field.key], field, item) : item[field.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {pagination && (
        <div className={styles.pagination}>
          {maxPage > 1 && (
            <>
              <span
                onClick={prevPage}
                className={classNames(fi('flaticon-left-arrow'), styles.previousPagination)}
              />
              <span
                onClick={nextPage}
                className={classNames(fi('flaticon-right-arrow'), styles.nextPagination)}
              />
            </>
          )}
          {renderPageNumbers()}
        </div>
      )}
    </>
  )
}

Table.propTypes = {
  fields: PropTypes.array,
  data: PropTypes.array,
  page: PropTypes.number,
  nextPage: PropTypes.func,
  prevPage: PropTypes.func,
  goToPage: PropTypes.func,
  pagination: PropTypes.bool,
}

export default Table
