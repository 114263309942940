import NumberFormat from 'react-number-format'
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import formStyles from 'component/form/formFields.module.scss'

const TelNumberInput = (props) => {
  const {inputRef,label, onChange, prefix, suffix = '', name,meta,input, styles, ...other} = props
  const classNames = cx(formStyles.field, !!input?.value ? formStyles.notEmpty : '', styles)
  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={classNames}>
      <label className={formStyles.label}>{label && label}</label>
    <NumberFormat
      {...other}
      format={'### ### ###'}
      type={'text'}
      suffix={suffix}
      prefix={prefix}
      isNumericString={true}
      className={cx(formStyles.textField, errorMessage ? formStyles.errorField : '')}
      getInputRef={inputRef}
      onValueChange={(values) => {
        props?.input?.onChange({
          target: {
            name: name,
            value: values.value,
          },
        })
      }}
      thousandSeparator={' '}
    />
    </div>
  )
}

TelNumberInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
}

export default TelNumberInput
