import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {GET_POST_FAIL, GET_POST_REQUEST, GET_POST_SUCCESS} from 'redux/actionTypes'

export const getPosts = () =>
  function (dispatch) {
    dispatch({type: GET_POST_REQUEST})

    return httpClient
      .get(`${endpoints.interests}`)
      .then((response) => {
        dispatch({
          type: GET_POST_SUCCESS,
          data: response.data,
        })
      })
      .catch((err) => {
        dispatch({type: GET_POST_FAIL})
        globalApiErrorHandler(err)
      })
  }
