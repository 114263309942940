import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'
import {
  GET_RECOMMENDATIONS_FAIL,
  GET_RECOMMENDATIONS_REQUEST,
  GET_RECOMMENDATIONS_SUCCESS,
} from 'redux/actionTypes'

export const getRecommendations = (limit = 1000, offset = 0) => {
  return (dispatch) => {
    dispatch({type: GET_RECOMMENDATIONS_REQUEST})
    return httpClient
      .get(endpoints.recommendations, {
        limit: limit,
        offset: offset,
      })
      .then((res) => {
        dispatch({type: GET_RECOMMENDATIONS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        dispatch({type: GET_RECOMMENDATIONS_FAIL})
        return Promise.reject(err.response?.data?.errors)
      })
  }
}

export const createRecommendations = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.recommendations, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err.response?.data?.errors)
      })
  }
}
