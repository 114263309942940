import React, {useEffect} from 'react'
import styles from 'page/postPage.module.scss'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getPosts} from 'redux/actions/postActions'
import Loader from 'component/Loader'

const PostPage = (props) => {
  const {getPosts, posts, loading} = props

  useEffect(() => {
    getPosts()
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  const PostPageCard = (post, index) => {
    let numberOfWords = post.description.split(' ')
    if (numberOfWords.length > 20) {
      let lengthOfWords = numberOfWords.length - 20
      numberOfWords = numberOfWords.slice(0, numberOfWords.length - lengthOfWords)
      numberOfWords.push('...')
    }

    return (
      <a key={index} className={styles.postCard} target="_blank" href={post.link} rel="noreferrer">
        <div className={styles.postImage}>
          <img src={post.image_url} alt={post.link} />
        </div>
        <div className={styles.postTitle}>{post.title}</div>

        <div
          className={styles.postText}
          dangerouslySetInnerHTML={{__html: numberOfWords.join(' ')}}
        />
      </a>
    )
  }

  return (
    <div className={styles.PostPage}>
      <div className={styles.topPartBackground}>
        <div className={styles.topPart}>Zajímavosti</div>
        <div className={styles.topPartText}>
          Uvedené informace či závěry mají toliko obecnou a informativní hodnotu, pro jakékoli
          závěry v konkrétní věci je vždy nezbytné pečlivě zohlednit specifika a okolnosti dané
          věci.
        </div>
      </div>
      <div className={styles.postsContainer}>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.postCardsContainer}>
            {posts?.map((post, index) => PostPageCard(post, index))}
          </div>
        )}
      </div>
    </div>
  )
}

PostPage.propTypes = {
  postsMeta: PropTypes.object,
  getPosts: PropTypes.func,
  loading: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    loading: state.posts.getInProgress,
    posts: state.posts.data,
    postsMeta: state.posts.meta,
  }
}

const mapDispatchToProps = {
  getPosts,
}

export default connect(mapStateToProps, mapDispatchToProps)(PostPage)
