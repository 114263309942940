import {GET_INVOICES_REQUEST, GET_INVOICES_SUCCESS, GET_INVOICES_FAIL} from 'redux/actionTypes'

const initState = {
  invoices: [],
  invoicesLoading: false,
  invoicesMeta: {},
}

const invoiceReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_INVOICES_REQUEST: {
      return {
        ...state,
        invoicesLoading: true,
      }
    }
    case GET_INVOICES_SUCCESS: {
      return {
        ...state,
        invoicesLoading: false,
        invoices: action.data.objects,
        invoicesMeta: action.data.meta,
      }
    }
    case GET_INVOICES_FAIL: {
      return {
        ...state,
        invoicesLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default invoiceReducer
