import React, {useEffect, useState} from 'react'
import style from './contacts.module.scss'
import {CONTRACT_KIND, CONTRACT_STATUS, ESTATE_KIND, TABLET_SCREEN_WIDTH} from 'helper/constants'
import styles from 'component/table.module.scss'
import classNames from 'classnames/bind'
import {ReactComponent as Dots} from 'asset/img/three_dots.svg'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import detailPageStyle from 'component/detailPageMenu.module.scss'
import {getClients, updateFilter} from 'redux/actions/clientAction'
import {connect} from 'react-redux'
import Loader from 'component/Loader'
import cx from 'classnames'

const fi = classNames.bind(flaticon)
let searchInterval = null

const Contacts = (props) => {
  const {getClients, clients, clientsMeta, clientsLoading, filters, updateFilter} = props

  const [page, setPage] = useState(0)

  const tabletView = window.innerWidth < TABLET_SCREEN_WIDTH

  const maxPage = Math.ceil(clientsMeta.total_count / clientsMeta.limit)

  const renderPageNumbers = () => {
    const pages = []

    const finalPages = []

    // add one to solve index issue (index starts 0, page 1)
    // subtract two for edge page number
    const leftEdgePageNumber = page + 1 - 2
    const rightEdgePageNumber = maxPage - page - 2

    // returns 5 pages, 2 from left, current and 2 from right
    for (let i = leftEdgePageNumber; i <= page + 1 + 2; i++) {
      if (i > 0 && i <= maxPage) {
        if (!finalPages.includes(i)) {
          finalPages.push(i)
        }
      }
    }

    // left side of pagination (pages and dots)
    if (leftEdgePageNumber === 2) {
      finalPages.unshift(1)
    } else if (leftEdgePageNumber === 3) {
      finalPages.unshift(...[1, 2])
    } else if (leftEdgePageNumber > 3) {
      finalPages.unshift(...[1, 'threeDots'])
    }

    // right side of pagination (pages and dots)
    if (rightEdgePageNumber === 2) {
      finalPages.push(maxPage)
    } else if (rightEdgePageNumber === 3) {
      finalPages.push(...[maxPage - 1, maxPage])
    } else if (rightEdgePageNumber > 3) {
      finalPages.push(...['threeDots', maxPage])
    }

    finalPages.forEach((pageNumber, index) => {
      if (pageNumber === 'threeDots') {
        pages.push(
          <div className={styles.dots} key={`threeDots${index}`}>
            <Dots />
          </div>
        )
      } else {
        pages.push(
          <div
            className={classNames(styles.page, page === pageNumber - 1 ? styles.active : '')}
            key={pageNumber}
            onClick={() => goToPage(pageNumber - 1)}
          >
            {pageNumber}
          </div>
        )
      }
    })

    return pages
  }

  const handleNextPage = () => {
    if (clientsMeta.limit * (page + 1) < clientsMeta.total_count) {
      setPage((prevState) => prevState + 1)
    }
  }

  const goToPage = (page) => {
    if (page > 0 || clientsMeta.limit * (page + 1) < clientsMeta.total_count) {
      setPage(page)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevState) => prevState - 1)
    }
  }

  const debounceFilter = (filter) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      updateFilter(filter)
    }, 500)
  }

  const handleChangeFilter = (e) => {
    const name = e.target.name
    let filter = {[name]: e.target.value}
    if (name === 'name') {
      return debounceFilter(filter)
    } else {
      return updateFilter(filter)
    }
  }

  useEffect(() => {
    getClients(page, filters)
  }, [filters])

  return (
    <div className={style.contacts}>
      {tabletView && (
        <span className={detailPageStyle.filterContainer}>
          <select
            className={style.filterField}
            name="interested_in_status"
            defaultValue={''}
            onChange={handleChangeFilter}
          >
            {CONTRACT_STATUS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <input
            className={style.filterField}
            name="name"
            onChange={handleChangeFilter}
            autoComplete="off"
            placeholder="Jméno klienta"
          />
        </span>
      )}
      <div className={style.tableHeaderRow}>
        <div>Klient</div>
        <div>Kontakt</div>
        <div>Nemovitost</div>
        <div className={style.contractTypeCell}>Typ obchodu</div>
        <div className={style.contractStatusCell}>Stav obchodu</div>
      </div>
      {clientsLoading ? (
        <Loader />
      ) : (
        <div className={style.table}>
          {clients.map((client, index) => (
            <div key={index} className={style.tableRow}>
              <div>{client?.name}</div>
              <div className={style.contactCell}>
                <span>{client?.phone}</span>
                <span>{client?.email}</span>
              </div>
              <div className={style.contactCell}>
                <span>
                  {client?.estate_kind &&
                    ESTATE_KIND.find((contract) => contract.value === client.estate_kind)?.label}
                </span>
                <span>{client?.estate_city}</span>
              </div>
              <div className={style.contractTypeCell}>
                {client?.interested_in &&
                  CONTRACT_KIND.find((contract) => contract.value === client.interested_in)?.label}
              </div>
              <div className={style.contractStatusCell}>
                {client.interested_in_status&&client.interested_in_status !== null && (
                  <span
                    className={cx(
                      style.contractStatus,
                      client?.interested_in_status === 'signed'
                        ? style.orange
                        : client?.interested_in_status === 'active'
                        ? style.green
                        : style.gray
                    )}
                  >
                    {client?.interested_in_status &&
                      CONTRACT_STATUS.find(
                        (contract) => contract.value === client.interested_in_status
                      )?.label}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={styles.pagination}>
        {maxPage > 1 && (
          <>
            <span
              onClick={handlePreviousPage}
              className={classNames(fi('flaticon-left-arrow'), styles.previousPagination)}
            />
            <span
              onClick={handleNextPage}
              className={classNames(fi('flaticon-right-arrow'), styles.nextPagination)}
            />
          </>
        )}
        {renderPageNumbers()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients.clients,
    clientsLoading: state.clients.clientsLoading,
    clientsMeta: state.clients.clientsMeta,
    filters: state.clients.filters,
  }
}

const mapDispatchToProps = {
  getClients,
  updateFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
