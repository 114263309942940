import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames/bind'
import {useHistory} from 'react-router-dom'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import styles from './homePage.module.scss'
import {getRecommenders} from 'redux/actions/impersonationActions'
import HomePageMenu from 'component/HomePageMenu'
import {getProfile, createUserImpersonation, destroyUserImpersonation} from 'redux/actions/user'
import formStyles from 'component/form/formFields.module.scss'
import Select from 'react-dropdown-select'
import Dialog from 'component/Dialog/Dialog'
import PropTypes from 'prop-types'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const HomePage = (props) => {
  const {
    user,
    impersonation,
    impersonationLoading,
    getRecommenders,
    createUserImpersonation,
    destroyUserImpersonation,
    getProfile,
  } = props

  const showImpersonation = user?.data?.can_impersonate && !user.impersonated
  const [oSpolecnostiDialogVisible, setOSpolecnostiDialogVisible] = useState(false)
  const [najemniPohadkaDialogVisible, setNajemniPohadkaDialogVisible] = useState(false)

  const routerHistory = useHistory()

  let defaultImpersonation = null

  try {
    defaultImpersonation = JSON.parse(localStorage.getItem('impersonation'))
  } catch {}

  const [selectedImpersonation, setSelectedImpersonation] = useState(defaultImpersonation)

  const goToDetail = () => {
    routerHistory.push(`/spolupracovnici/1`)
  }

  const createImpersonation = () => {
    if (
      selectedImpersonation !== null &&
      (selectedImpersonation?.id === 0 || selectedImpersonation?.id)
    ) {
      createUserImpersonation(selectedImpersonation.id)
    }
  }

  const handleSelectImpersonationChange = (value) => {
    setSelectedImpersonation(value[0])
  }

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  useEffect(() => {
    if (user.logoutProgress) {
      return
    }
    if (showImpersonation) {
      getRecommenders()
    }
  }, [showImpersonation, getRecommenders])

  return (
    <div className={styles.HomePage}>
      <div className={styles.content}>
        <span className={classNames(fi('flaticon-hotel'), cx('iconHotel'))} />
        <div className={styles.greeting}>Vítejte v zóně pro spolupracovníky,</div>
        <div className={styles.greetingDivider} />
        <div className={styles.centerText}>
          {showImpersonation ? (
            <>níže vyberte, za kterého uživatele se chcete přihlásit </>
          ) : (
            <>nyní vstupte do svého profilu (uživatelského účtu). </>
          )}
        </div>

        {showImpersonation ? (
          <>
            <div className={classNames(formStyles.selectField, styles.selectWrapper)}>
              <Select
                className={classNames('homePage')}
                placeholder="Vyberte uživatele"
                options={impersonation}
                searchBy={'name'}
                labelField={'name'}
                valueField={'id'}
                onChange={handleSelectImpersonationChange}
                loading={!impersonationLoading}
              />
            </div>
            <button className={styles.buttonCreateImpersonation} onClick={createImpersonation}>
              Přihlásit se
            </button>
          </>
        ) : (
          <>
            <button className={styles.buttonEnter} onClick={goToDetail}>
              Potvrdit a vstoupit
            </button>
            {user.impersonated && (
              <button
                className={styles.buttonDestroyImpersonation}
                onClick={destroyUserImpersonation}
              >
                Přihlásit se za jiného uživatele
              </button>
            )}
          </>
        )}
      </div>

      <HomePageMenu
        disablePersonalItems={showImpersonation}
        oSpolecnostiOnClick={setOSpolecnostiDialogVisible.bind(null, true)}
        najemniPohadkaOnClick={setNajemniPohadkaDialogVisible.bind(null, true)}
      />

      {oSpolecnostiDialogVisible && (
        <Dialog title="O společnosti" handleClose={setOSpolecnostiDialogVisible.bind(null, false)}>
          <div className={styles.oSpolecnosti}>
            <p>Psal se rok 2013, kdy světlo světa poprvé spatřila naše společnost.</p>
            <p>
              Stovky a stovky hodin příprav se konečně přetavily v něco, v co jsme věřili, že se
              jednou stane skutečností. Našim cílem bylo už od samého počátku stvořit službu,
              která změní zažité standardy na realitním (nájemním) trhu a bude představovat
              skutečnou přidanou hodnotu. Jen tak je možné dosáhnout toho, aby měla naše práce
              hlubší smysl a mělo význam na ní dlouhodobě pracovat.
            </p>
            <p>Podařilo se!</p>
            <p>
              Dnes naše portfolio čítá stovky spokojených klientů, kterým se staráme o pronájem
              jejich nemovitostí a díky tomu přinášíme do jejich života klid, čas, pohodu a
              zpravidla i pravidelný přísun peněz.
            </p>
            <p>
              Jsme rádi, že i Vy spatřujete v naší práci smysl a přínos pro Vaše kontakty. Věříme,
              že i Vámi doporučení klienti naší práci ocení a budou Vám vděční za Vaše doporučení.
            </p>
            <p>S úctou</p>
            <p>
              Martin Škoda <br />
              jednatel společnosti
            </p>
          </div>
        </Dialog>
      )}
      {najemniPohadkaDialogVisible && (
        <Dialog
          title="Nájemní pohádka"
          handleClose={setNajemniPohadkaDialogVisible.bind(null, false)}
        >
          <div className={styles.najemniPohadka}>
            <iframe
              title={'Nájemní pohádka'}
              className={styles.najemniPohadkaIFrame}
              src="https://www.youtube.com/embed/yGaNE6nwwf4"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Dialog>
      )}
    </div>
  )
}

HomePage.propTypes = {
  getRecommenders: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  impersonation: state.impersonation.recommenders,
  impersonationLoading: state.impersonation.recommendersLoading,
})

const mapDispatchToProps = {
  getProfile,
  getRecommenders,
  createUserImpersonation,
  destroyUserImpersonation,
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
