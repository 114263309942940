import React from 'react'
import {connect} from 'react-redux'
import Layout from 'App/Layout'
import {Redirect, Route} from 'react-router-dom'

const PrivateRoute = (props) => {
  const {user, component, ...rest} = props

  if (!user?.logged) {
    return <Redirect to="/login" />
  }

  return (
    <Route {...rest}>
      <Layout>{component}</Layout>
    </Route>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
