import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {Form, Field} from 'react-final-form'
import styles from './loginPage.module.scss'
import formStyles from 'component/form/formFields.module.scss'
import {userLogin} from 'redux/actions/user'
import PasswordInput from 'component/form/PasswordInput'
import {required} from 'helper/validations'
import TextInput from 'component/form/TextInput'
import './autofill.scss'

const LoginPage = (props) => {
  const {user, userLogin} = props
  const _userLogin = (values) => userLogin({email: values.email, password: values.password})

  useEffect(() => {
    const AUTOFILLED = 'is-autofilled'

    const onAutoFillStart = (el) => {
      document.querySelectorAll('.login-field').forEach((node) => node.classList.add(AUTOFILLED))
    }

    const onAutoFillCancel = (el) => {
      document.querySelectorAll('.login-field').forEach((node) => node.classList.remove(AUTOFILLED))
    }

    const onAnimationStart = ({target, animationName}) => {
      switch (animationName) {
        case 'onAutoFillStart':
          return onAutoFillStart(target)
        case 'onAutoFillCancel':
          return onAutoFillCancel(target)
        default:
          return
      }
    }

    document
      .querySelectorAll('.login-field')
      .forEach((node) => node.addEventListener('animationstart', onAnimationStart, false))
  }, [])

  return (
    <div className={styles.LoginPage}>
      {user?.logged && <Redirect to="/" />}
      <div className={styles.title}>Přihlášení</div>
      <Form
        onSubmit={_userLogin}
        render={({handleSubmit, form, submitting, pristine, values}) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                styles="login-field"
                name="email"
                label="E-mail"
                component={TextInput}
                validate={required}
              />
              <Field
                styles="login-field"
                name="password"
                label="Heslo"
                component={PasswordInput}
                validate={required}
              />

              <div className={formStyles.buttons}>
                <button className={formStyles.button} type="submit" disabled={submitting}>
                  PŘIHLÁSIT SE
                </button>
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  userLogin,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
