import React from 'react'
import {connect} from 'react-redux'
import {Link, useRouteMatch, useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from 'component/detailPageMenu.module.scss'
import classNames from 'classnames/bind'
import {CONTRACT_STATUS, TABLET_SCREEN_WIDTH} from 'helper/constants'
import Select from 'react-dropdown-select'
import {updateFilter} from 'redux/actions/clientAction'

const cx = classNames.bind(styles)
let searchInterval = null

const DetailPageMenu = (props) => {
  const {activeLink, updateFilter} = props

  let routeMatch = useRouteMatch()
  let history = useHistory()

  const mobileView = window.innerWidth < TABLET_SCREEN_WIDTH

  const links = [
    {to: 'prehled', text: 'Přehled'},
    {to: 'kontakty', text: 'Kontakty'},
    {to: 'fakturace', text: 'Fakturace'},
  ]

  const handleClickOnLink = (link) => {
    if (link[0]) {
      let urlParts = routeMatch.url.split('/')
      urlParts[urlParts.length - 1] = link[0].to
      history.push(urlParts.join('/'))
    } else {
      let urlParts = routeMatch.url.split('/')
      urlParts[urlParts.length - 1] = 'prehled'
      history.push(urlParts.join('/'))
    }
  }

  const debounceFilter = (filter) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      updateFilter(filter)
    }, 500)
  }

  const handleChangeFilter = (e) => {
    const name = e.target.name
    let filter = {[name]: e.target.value}
    if (name === 'name') {
      return debounceFilter(filter)
    } else {
      return updateFilter(filter)
    }
  }

  return mobileView ? (
    <>
      <Select
        className="detail-page-select"
        values={[links.find((link) => link.to === activeLink)]}
        options={links}
        labelField={'text'}
        valueField={'to'}
        searchable={false}
        backspaceDelete={false}
        onChange={handleClickOnLink}
        dropdownGap={0}
      />
    </>
  ) : (
    <span>
      <div className={styles.DetailPageMenu}>
        <span>
          {links.map((link) => (
            <div key={link.to} className={cx('linkWrapper', {active: activeLink === link.to})}>
              <Link to={link.to} className={cx('link', {active: activeLink === link.to})}>
                {link.text}
              </Link>
            </div>
          ))}
        </span>
        {activeLink === 'kontakty' && (
          <span className={styles.filterContainer}>
            <select
              className={styles.filterField}
              name="interested_in_status"
              onChange={handleChangeFilter}
            >
              {CONTRACT_STATUS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <input
              className={styles.filterField}
              name="name"
              onChange={handleChangeFilter}
              autoComplete="off"
              placeholder="Jméno klienta"
            />
          </span>
        )}
      </div>
    </span>
  )
}

DetailPageMenu.propTypes = {
  activeLink: PropTypes.string,
  updateFilter: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  updateFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPageMenu)
