import axios from 'axios'
import {fireErrorToast} from 'helper/functions'
import {REACT_PUBLIC_API_KEY} from 'helper/constants'

axios.defaults.withCredentials = true

if (REACT_PUBLIC_API_KEY) {
  axios.defaults.headers.common['X-PUBLIC-API-KEY'] = REACT_PUBLIC_API_KEY
}

axios.interceptors.request.use((config) => {
  const configCopy = {...config}
  const sessionId = localStorage.getItem('sessionId')

  if (sessionId) {
    configCopy.headers['Session-Id'] = sessionId
  }

  return configCopy
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (!error.response) {
      fireErrorToast('Server connection issue')
      throw new Error('Server connection issue')
    }

    return Promise.reject(error)
  }
)

const post = (
  url,
  data = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  return axios.post(url, data, config)
}

const get = (
  url,
  params = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  return axios.get(url, {...config, params})
}

const put = (url, data = {}, config = {}) => {
  return axios.put(url, data, config)
}

const patch = (url, data = {}, config = {}) => {
  return axios.patch(url, data, config)
}

const del = (url, config = {}) => {
  return axios.delete(url, config)
}

const httpClient = {
  post,
  get,
  put,
  del,
  patch,
}

export default httpClient
