import {
  CLIENT_FILTER_UPDATE,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
} from 'redux/actionTypes'

const initState = {
  clients: [],
  clientsMeta: {},
  clientsLoading: false,
  filters: {},
}

const clientReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CLIENTS_REQUEST: {
      return {
        ...state,
        clientsLoading: true,
      }
    }
    case GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        clientsLoading: false,
        clientsMeta: action.data.meta,
        clients: action.data.objects,
      }
    }
    case GET_CLIENTS_FAIL: {
      return {
        ...state,
        clientsLoading: false,
      }
    }
    case CLIENT_FILTER_UPDATE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default clientReducer
