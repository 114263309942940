import React from 'react'
import formStyles from 'component/form/formFields.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'

const TextInput = (props) => {
  const {label, meta, input, styles, ...rest} = props

  const classNames = cx(formStyles.field, !!input?.value ? formStyles.notEmpty : '', styles)
  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={classNames}>
      <label className={formStyles.label}>{label && label}</label>
      <input
        {...input}
        {...rest}
        className={cx(formStyles.textField, errorMessage ? formStyles.errorField : '')}
        type="text"
      />
      {errorMessage && <span className={formStyles.errorMessage}>{errorMessage}</span>}
    </div>
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  styles: PropTypes.string,
}

export default TextInput
