import React, {useEffect, useState} from 'react'
import phone from 'asset/img/mobile_phone.png'
import style from './overview.module.scss'
import Dialog from 'component/Dialog/Dialog'
import {getProfileLevel, rescueProfileLevel} from 'redux/actions/user'
import {connect} from 'react-redux'
import Loader from 'component/Loader'
import moment from 'moment'
import {DATE_FORMAT} from 'helper/constants'
import {getRecommendations} from 'redux/actions/recommendationActions'
import formStyles from 'component/form/formFields.module.scss'
import {ReactComponent as TouchAppIcon} from 'asset/img/touch_app.svg'

const Overview = (props) => {
  const {
    profileLevel,
    rescueProfileLevel,
    getProfileLevel,
    getRecommendations,
    recommendationsLoading,
    recommendationsMeta,
    recommendations,
  } = props

  const [openDialog, setOpenDialog] = useState(false)
  const [sosLoading, setSosLoading] = useState(false)
  const [sosDialog, setSosDialog] = useState(false)

  const handleOpenSos = () => {
    setSosDialog(true)
  }
  const handleCloseSos = () => {
    setSosDialog(false)
  }

  const handleSubmitSos = () => {
    setSosLoading(true)
    return rescueProfileLevel().then(() => {
      setSosLoading(false)
      getProfileLevel()
      handleCloseSos()
    })
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const renderContactsTable = () => {
    return recommendationsLoading ? (
      <Loader />
    ) : (
      <div className={style.table}>
        <div className={style.tableHeaderRow}>
          <div>Jméno a příjmení</div>
          <div>Odeslán dne</div>
          <div>Akceptován dne</div>
          <div>K uzavření do</div>
        </div>
        {recommendations.map((recommendation) => (
          <div key={recommendation?.id} className={style.tableRow}>
            <div>{recommendation?.name}</div>
            <div>
              {recommendation?.created_at && moment(recommendation.created_at).format(DATE_FORMAT)}
            </div>
            <div>
              {recommendation?.accepted_at && moment(recommendation.accepted_at).format(DATE_FORMAT)}
            </div>
            <div>
              {recommendation?.accepted_at && moment(recommendation?.accepted_at)
                  .add(60, 'days')
                  .format(DATE_FORMAT)}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const renderSosContent = () => {
    return (
      <div className={formStyles.dialogText}>
        <p>Opravdu chcete provést obnovení?</p>
        <p>Tuto akci lze provést pouze jednou.</p>
        <div className={formStyles.buttons}>
          <button onClick={handleCloseSos} className={formStyles.cancelButton}>
            ZAVŘÍT
          </button>
          <button onClick={handleSubmitSos} className={formStyles.button} type="submit">
            OBNOVIT
          </button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    getRecommendations()
  }, [])

  return (
    <>
      {openDialog && (
        <Dialog
          title="Kontakty k uzavření"
          handleClose={handleCloseDialog}
          content={renderContactsTable()}
        />
      )}

      {sosDialog && (
        <Dialog
          title="SOS"
          handleClose={handleCloseSos}
          content={renderSosContent()}
          small={true}
        />
      )}

      <div className={style.overview}>
        {/* PHONE */}
        {!profileLevel.is_level_disabled && (
          <div className={style.phoneImgContainer}>
            <span>
              Na akceptaci kontatu zbývá
              <div className={style.phoneNumber}>
                {sosLoading ? <Loader /> : profileLevel?.level_remaining_days}
              </div>
              <div className={style.daysText}>dní</div>
              <div
                className={profileLevel?.is_level_rescuable ? style.sosButton : style.sosDisabled}
                onClick={handleOpenSos}
              >
                {profileLevel?.is_level_rescuable ? (
                  <div>
                    <div className={style.sosText}>SOS</div>
                    <div className={style.sosSubText}>(obnovit na 100 dní)</div>
                  </div>
                ) : (
                  <div>
                    <div className={style.sosText}>SOS</div>
                    <div className={style.sosSubText}>(již použito)</div>
                  </div>
                )}
              </div>
            </span>

            <img src={phone} alt={'phone'} />
          </div>
        )}
        <div className={style.numberBoxGreen} onClick={handleOpenDialog}>
          <div className={style.numberBoxTextGreen}>Počet kontaktů k zobchodování</div>
          <div className={style.numberBoxNumber}>{recommendationsMeta?.total_count}</div>
          <div className={style.clickIcon}>
            <TouchAppIcon />
          </div>
        </div>
        <div className={style.numberBox}>
          <div className={style.numberBoxTextRed}>Odměna z jednorázového pronájmu</div>
          <div className={style.numberBoxPercent}>
            {profileLevel?.lease_commission ? profileLevel?.lease_commission : 0}
            <span>%</span>
          </div>
        </div>
        <div className={style.numberBox}>
          <div className={style.numberBoxTextRed}>Odměna ze správy pronájmu</div>
          <div className={style.numberBoxPercent}>
            {profileLevel?.management_commission ? profileLevel.management_commission : 0}
            <span>%</span>
          </div>
        </div>
        <div className={style.numberBox}>
          <div className={style.numberBoxTextRed}>Odměna z prodeje nemovitosti</div>
          <div className={style.numberBoxPercent}>
            {profileLevel?.sale_commission ? profileLevel.sale_commission : 0}
            <span>%</span>
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = {
  rescueProfileLevel,
  getProfileLevel,
  getRecommendations,
}

const mapStateToProps = (state) => ({
  user: state.user,
  recommendations: state.recommendationsReducer.recommendations,
  recommendationsMeta: state.recommendationsReducer.recommendationsMeta,
  recommendationsLoading: state.recommendationsReducer.recommendationsLoading,
})

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
