import {GET_POST_REQUEST, GET_POST_SUCCESS} from 'redux/actionTypes'

const initState = {
  dataReceived: false,
  getInProgress: false,
  data: [],
  meta: {},
}

const getPostsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_POST_REQUEST: {
      return {...state, getInProgress: true}
    }
    case GET_POST_SUCCESS: {
      return {
        ...state,
        dataReceived: true,
        getInProgress: false,
        data: action.data.objects,
        meta: action.data.meta,
      }
    }
    default: {
      return state
    }
  }
}

export default getPostsReducer
