import React from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'

import styles from './homePageMenuItem.module.scss'

const HomePageMenuItem = (props) => {
  const {icon, text, color, path, onClick} = props
  let history = useHistory()

  const handleOnClick = () => {
    if (path) {
      history.push(path)
    }
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      onClick={handleOnClick}
      className={styles.HomePageMenuItem}
      style={{borderTopColor: color}}
    >
      <img src={icon} className={styles.img} alt={text} />
      <div className={styles.text}>{text}</div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageMenuItem)
