export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGIN_REFRESH_REQUEST = 'USER_LOGIN_REFRESH_REQUEST'
export const USER_LOGIN_REFRESH_SUCCESS = 'USER_LOGIN_REFRESH_SUCCESS'
export const USER_LOGIN_REFRESH_FAIL = 'USER_LOGIN_REFRESH_FAIL'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL'
export const GET_PROFILE_BROKER_REQUEST = 'GET_PROFILE_BROKER_REQUEST'
export const GET_PROFILE_BROKER_SUCCESS = 'GET_PROFILE_BROKER_SUCCESS'
export const GET_PROFILE_BROKER_FAIL = 'GET_PROFILE_BROKER_FAIL'
export const GET_PROFILE_LEVEL_REQUEST = 'GET_PROFILE_LEVEL_REQUEST'
export const GET_PROFILE_LEVEL_SUCCESS = 'GET_PROFILE_LEVEL_SUCCESS'
export const GET_PROFILE_LEVEL_FAIL = 'GET_PROFILE_LEVEL_FAIL'

export const GET_RECOMMENDATIONS_REQUEST = 'GET_RECOMMENDATIONS_REQUEST'
export const GET_RECOMMENDATIONS_SUCCESS = 'GET_RECOMMENDATIONS_SUCCESS'
export const GET_RECOMMENDATIONS_FAIL = 'GET_RECOMMENDATIONS_FAIL'

export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST'
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL'

export const GET_USER_PROFILE_MANAGER_REQUEST = 'GET_USER_PROFILE_MANAGER_REQUEST'
export const GET_USER_PROFILE_MANAGER_SUCCESS = 'GET_USER_PROFILE_MANAGER_SUCCESS'
export const GET_USER_PROFILE_MANAGER_FAIL = 'GET_USER_PROFILE_MANAGER_FAIL'

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'
export const EDIT_PROFILE_FAIL = 'EDIT_PROFILE_FAIL'

export const EDIT_PASSWORD_REQUEST = 'EDIT_PASSWORD_REQUEST'
export const EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS'
export const EDIT_PASSWORD_FAIL = 'EDIT_PASSWORD_FAIL'

export const USER_CREATE_IMPERSONATION_REQUEST = 'USER_CREATE_IMPERSONATION_REQUEST'
export const USER_CREATE_IMPERSONATION_SUCCESS = 'USER_CREATE_IMPERSONATION_SUCCESS'
export const USER_CREATE_IMPERSONATION_FAIL = 'USER_CREATE_IMPERSONATION_FAIL'

export const USER_DESTROY_IMPERSONATION_REQUEST = 'USER_DESTROY_IMPERSONATION_REQUEST'
export const USER_DESTROY_IMPERSONATION_SUCCESS = 'USER_DESTROY_IMPERSONATION_SUCCESS'
export const USER_DESTROY_IMPERSONATION_FAIL = 'USER_DESTROY_IMPERSONATION_FAIL'

export const GET_DOWNLOAD_KEY_REQUEST = 'GET_DOWNLOAD_KEY_REQUEST'
export const GET_DOWNLOAD_KEY_SUCCESS = 'GET_DOWNLOAD_KEY_SUCCESS'
export const GET_DOWNLOAD_KEY_FAIL = 'GET_DOWNLOAD_KEY_FAIL'

export const GET_IMPERSONATION_REQUEST = 'GET_IMPERSONATION_REQUEST'
export const GET_IMPERSONATION_SUCCESS = 'GET_IMPERSONATION_SUCCESS'
export const GET_IMPERSONATION_FAIL = 'GET_IMPERSONATION_FAIL'

export const GET_STATISTICS_REQUEST = 'GET_STATISTICS_REQUEST'
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS'
export const GET_STATISTICS_FAIL = 'GET_STATISTICS_FAIL'

export const SEND_DOPORUCIT_REQUEST = 'SEND_DOPORUCIT_REQUEST'
export const SEND_DOPORUCIT_SUCCESS = 'SEND_DOPORUCIT_SUCCESS'
export const SEND_DOPORUCIT_FAIL = 'SEND_DOPORUCIT_FAIL'

export const GET_POST_REQUEST = 'GET_POST_REQUEST'
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS'
export const GET_POST_FAIL = 'GET_POST_FAIL'

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST'
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS'
export const GET_INVOICES_FAIL = 'GET_INVOICES_FAIL'

export const CLIENT_FILTER_UPDATE = 'CLIENT_FILTER_UPDATE'
