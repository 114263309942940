import {fireErrorToast, globalApiErrorHandler, rethrow} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_DOWNLOAD_KEY_REQUEST,
  GET_DOWNLOAD_KEY_SUCCESS,
  GET_DOWNLOAD_KEY_FAIL,
} from 'redux/actionTypes'

export const download = (downloadUrl) =>
  function (dispatch) {
    dispatch({type: GET_DOWNLOAD_KEY_REQUEST})
    return httpClient
      .get(downloadUrl)
      .then((response) => {
        if (!response?.data?.download_key) {
          throw new Error('Missing download_key in server response')
        }
        window.open(`${downloadUrl}/${response.data.download_key}`, '_blank')
        return dispatch({
          type: GET_DOWNLOAD_KEY_SUCCESS,
          data: response.data,
        })
      })
      .catch(
        rethrow(() => {
          fireErrorToast('Chyba stahování souboru')
          dispatch({type: GET_DOWNLOAD_KEY_FAIL})
        })
      )
      .catch(globalApiErrorHandler)
  }
