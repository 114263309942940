import React from 'react'
import styles from 'component/yourContact/yourContact.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import classNames from 'classnames/bind'
import {TABLET_SCREEN_WIDTH} from 'helper/constants'

const fi = classNames.bind(flaticon)

const YourContact = (props) => {
  const {broker} = props

  const mobileView = window.innerWidth < TABLET_SCREEN_WIDTH

  const renderEmail = () => {
    if (mobileView) {
      const emailSplit = broker?.email?.split('@')
      return (
        <span>
          {emailSplit?.[0]}
          <br />@{emailSplit?.[1]}
        </span>
      )
    } else {
      return broker?.email
    }
  }

  return (
    <div className={styles.YourPropertyManager}>
      <div className={styles.title}>Váš kontakt</div>
      <div className={styles.detail}>
        <div className={styles.img}>
          <img src={broker?.photo_url} alt="" />
        </div>
        <div className={styles.information}>
          <div className={styles.name}>{broker?.name}</div>
          <div className={styles.phone}>
            <span className={classNames(fi('flaticon-call'))}>
              <a href={`tel:${broker?.phone}`}>{broker?.phone}</a>
            </span>
          </div>
          <div className={styles.sendMessageButton}>
            <span className={classNames(fi('flaticon-email'))} />
            <a href={`mailto:${broker?.email}`}>{renderEmail()}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YourContact
