import {
  GET_RECOMMENDATIONS_FAIL,
  GET_RECOMMENDATIONS_REQUEST,
  GET_RECOMMENDATIONS_SUCCESS,
} from 'redux/actionTypes'

const initState = {
  recommendationsLoading: false,
  recommendationsMeta: {},
  recommendations: [],
}

const recommendationsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_RECOMMENDATIONS_REQUEST: {
      return {
        ...state,
        recommendationsLoading: true,
      }
    }
    case GET_RECOMMENDATIONS_SUCCESS: {
      return {
        ...state,
        recommendationsLoading: false,
        recommendationsMeta: action.data.meta,
        recommendations: action.data.objects,
      }
    }
    case GET_RECOMMENDATIONS_FAIL: {
      return {
        ...state,
        recommendationsLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default recommendationsReducer
