import {combineReducers} from 'redux'
import user from 'redux/reducers/user'
import impersonationReducer from 'redux/reducers/impersonationReducer'
import statistics from 'redux/reducers/statistics'
import posts from 'redux/reducers/postsReducer'
import recommendationsReducer from 'redux/reducers/recommendationsReducer'
import clientReducer from 'redux/reducers/clientReducer'
import invoiceReducer from 'redux/reducers/invoiceReducer'

export default combineReducers({
  user,
  impersonation: impersonationReducer,
  statistics,
  posts,
  recommendationsReducer,
  clients: clientReducer,
  invoices: invoiceReducer,
})
